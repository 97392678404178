<template>
  <navigation index="attribute" ref="navigation">
    <div>
      <div class="topnavigation">
        <el-button
          type="primary"
          @click="
            OpenAttributeGroupWindow({
              ProductAttribute: [],
              Sort: 0,
            })
          "
        >
          {{ tools.GetLanguageValue("web.新增") }}
        </el-button>
      </div>

      <el-table border :data="AttributeGroup" :height="ContentHeight + 'px'">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column
          :label="tools.GetLanguageValue('web.属性名称')"
          width="180"
          prop="Name"
        />
        <!-- <el-table-column :label=" tools.GetLanguageValue('web.所属品牌')" width="180" prop="BrandName" />  -->
        <el-table-column
          :label="tools.GetLanguageValue('web.排序')"
          width="180"
          prop="Sort"
        />
        <el-table-column
          :label="tools.GetLanguageValue('web.备注')"
          width="180"
          prop="Desc"
        />

        <el-table-column
          :label="tools.GetLanguageValue('web.操作')"
          fixed="right"
          width="350"
        >
          <template #default="scope">
            <el-button
              type="primary"
              size="small"
              @click="OpenAttributeGroupWindow(scope.row)"
            >
              {{ tools.GetLanguageValue("web.编辑") }}</el-button
            >
            <el-button
              type="success"
              size="small"
              @click="GetAttributesByGroupId(scope.row)"
            >
              {{ tools.GetLanguageValue("web.编辑内容") }}</el-button
            >
            <el-button
              size="small"
              @click="DeleteAttributeGroupById(scope.row)"
              type="danger"
            >
              {{ tools.GetLanguageValue("web.删除") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 添加属性小项 -->
      <el-dialog
        v-model="AttributeItemWindow"
        :title="tools.GetLanguageValue('web.属性')"
        width="70%"
        :show-close="false"
      >
        <el-row style="margin: 5px">
          <el-col :span="15">
            <el-table border :data="AttributeGroupItemList" height="500px">
              <el-table-column type="index" width="50"> </el-table-column>
              <el-table-column
                :label="tools.GetLanguageValue('web.属性名称')"
                width="180"
                prop="Name"
              />
              <el-table-column
                :label="tools.GetLanguageValue('web.排序')"
                width="80"
                prop="Sort"
              />
              <el-table-column
                :label="tools.GetLanguageValue('web.备注')"
                width="180"
                prop="Desc"
              />
              <el-table-column
                :label="tools.GetLanguageValue('web.操作')"
                fixed="right"
                width="200"
              >
                <template #default="scope">
                  <el-button
                    type="success"
                    size="small"
                    @click="OpenttributeItemWindow(scope.row)"
                  >
                    {{ tools.GetLanguageValue("web.编辑") }}</el-button
                  >

                  <el-button
                    size="small"
                    @click="DeleteAttributeItem(scope.row)"
                    type="danger"
                  >
                    {{ tools.GetLanguageValue("web.删除") }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="8">
            <div style="width: 100%; height: 300px; padding: 15px">
              <el-form
                ref="form"
                label-width="120px"
                style="margin: 15px"
                v-model="AttributeGroupItemModel"
                :model="AttributeGroupItemModel"
                :rules="AttributeGroupRules"
              >
                <el-form-item
                  :label="tools.GetLanguageValue('web.项名称')"
                  prop="Name"
                >
                  <el-input
                    @focus="InputSelect($event)"
                    v-model="AttributeGroupItemModel.Name"
                    :placeholder="tools.GetLanguageValue('web.项名称')"
                  ></el-input>
                </el-form-item>
                <!-- <el-form-item
                  :label="tools.GetLanguageValue('web.单价')"
                  prop="Price"
                >
                  <el-input-number
                    v-model="AttributeGroupItemModel.Price"
                    :placeholder="tools.GetLanguageValue('web.请输入单价')"
                    @focus="InputSelect($event)"
                  ></el-input-number>
                </el-form-item> -->
                <el-form-item
                  :label="tools.GetLanguageValue('web.排序')"
                  prop="Sort"
                >
                  <el-input-number
                    v-model="AttributeGroupItemModel.Sort"
                    :placeholder="tools.GetLanguageValue('web.请输入排序')"
                    @focus="InputSelect($event)"
                  ></el-input-number>
                </el-form-item>
                <el-form-item
                  :label="tools.GetLanguageValue('web.备注')"
                  prop="Name"
                >
                  <el-input
                    v-model="AttributeGroupItemModel.Desc"
                    :placeholder="tools.GetLanguageValue('web.请输入备注')"
                    @focus="InputSelect($event)"
                  ></el-input>
                </el-form-item>
              </el-form>
              <div style="width: 100%; text-align: center">
                <el-button
                  type="primary"
                  style="width: 150px; height: 40px"
                  @click="InsertAttributeGroupItem"
                >
                  {{ tools.GetLanguageValue("web.保存") }}</el-button
                >
              </div>
            </div>
          </el-col>
        </el-row>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="AttributeItemWindow = !AttributeItemWindow">
              {{ tools.GetLanguageValue("web.关闭") }}</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
  </navigation>

  <!-- 新增属性小项 -->
  <el-dialog
    v-model="AttributeGroupItemWindow"
    :title="tools.GetLanguageValue('web.新增')"
    width="800px"
    :draggable="true"
    :show-close="false"
  >
    <el-form
      ref="form"
      label-width="120px"
      v-model="AttributeGroupItemModel"
      :model="AttributeGroupItemModel"
      :rules="AttributeGroupRules"
    >
      <el-form-item :label="tools.GetLanguageValue('web.项名称')" prop="Name">
        <el-input v-model="AttributeGroupItemModel.Name"></el-input>
      </el-form-item>
      <el-form-item :label="tools.GetLanguageValue('web.备注')" prop="Name">
        <el-input v-model="AttributeGroupItemModel.Desc"></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button
          @click="AttributeGroupItemWindow = !AttributeGroupItemWindow"
        >
          {{ tools.GetLanguageValue("web.关闭") }}</el-button
        >
        <el-button @click="InsertAttributeGroupItem" type="primary">
          {{ tools.GetLanguageValue("web.确定") }}</el-button
        >
      </span>
    </template>
  </el-dialog>

  <!-- 新增属性组 -->
  <el-dialog
    v-model="AttributeGroupWindow"
    :title="tools.GetLanguageValue('web.新增属性')"
    width="800px"
    :draggable="true"
    :show-close="false"
  >
    <el-tabs v-model="LanguageName">
      <el-tab-pane :label="tools.GetLanguageValue('web.默认')" name="first">
        <el-form
          ref="form"
          label-width="120px"
          v-model="AttributeGroupModel"
          :model="AttributeGroupModel"
          :rules="AttributeGroupRules"
          style="margin-top: 10px"
        >
          <el-form-item
            :label="tools.GetLanguageValue('web.属性名称')"
            prop="Name"
          >
            <el-input
              v-model="AttributeGroupModel.Name"
              @focus="InputSelect($event)"
              :placeholder="tools.GetLanguageValue('web.请输入属性名称')"
            ></el-input>
          </el-form-item>
 
          <el-form-item :label="tools.GetLanguageValue('web.排序')" prop="Name">
            <el-input-number
              v-model="AttributeGroupModel.Sort"
              @focus="InputSelect($event)"
            ></el-input-number>
          </el-form-item>
          <el-form-item :label="tools.GetLanguageValue('web.备注')" prop="Name">
            <el-input
              @focus="InputSelect($event)"
              v-model="AttributeGroupModel.Desc"
              :placeholder="tools.GetLanguageValue('web.请输入备注')"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>

      <el-tab-pane
          :label="item.LanguageName"
          :name="item.LanguageName"
          v-for="(item, index) in AttributeGroupModel.Languages"
          :key="index"
        >
          <el-form-item :label="tools.GetLanguageValue('web.名称')">
            <el-input
              v-model="item.Language.Name"
              @focus="InputSelect"
            ></el-input>
          </el-form-item>

        
        </el-tab-pane>


    </el-tabs>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="AttributeGroupWindow = !AttributeGroupWindow">
          {{ tools.GetLanguageValue("web.关闭") }}</el-button
        >
        <el-button @click="InsterProductAttributeGroup" type="primary">{{
          tools.GetLanguageValue("web.确定")
        }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script >
import axios from "../../../commons/AxiosMethod.js";
import tools from "../../../commons/tools.js";
import navigation from "../../../components/Modules/Navigation.vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { Plus } from "@element-plus/icons-vue";

export default {
  data() {
    return {
      AttributeGroup: [], //属性列表
      AttributeGroupModel: {}, //当前操作属性
      AttributeGroupWindow: false,
      AttributePadding: {
        Page: 1,
        Size: 30,
        Count: 0,
      },
      LanguageName: "first",
      AttributeGroupRules: [],
      AttributeItemWindow: false, //属性编辑列表
      CreateAttributeItemWindow: false,
      AttributeItemName: "",

      AttributeGroupItemWindow: false,
      AttributeGroupItemModel: {
        Price: 0,
        Sort: 0,
      }, //当前操作属性
      AttributeGroupItemList: {}, //当前操作属性

      AttributesGroupId: 0,

      CategoryOptions: "", //分类
      BrandOptions: "", //品牌
      ContentHeight: 900,
      clientHeight: document.documentElement.clientHeight,
      tools: tools,
    };
  },
  name: "App",
  mounted() {
    this.GetAttributeGroup();
    this.GetCategory();
    this.GetProductBrands();

    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.clientHeight = document.documentElement.clientHeight;
        this.ContentHeight = document.documentElement.clientHeight - 100;
        this.$refs.navigation.onresize();
      })();
    };
    this.ContentHeight = this.clientHeight - 100;
  },

  components: {
    navigation,
    Plus,
  },
  methods: {
    InputSelect(e) {
      console.log(e);
      e.currentTarget.select();
    },
    //编辑属性详细
    OpenttributeItemWindow(item) {
      this.AttributeGroupItemModel = item;
    },
    //获取品牌列表
    GetProductBrands() {
      axios.apiMethod(
        "/stores/Data/GetProductBrands",
        "post",
        { ScreenValue: "" },
        (result) => {
          if (result.Code == 200) {
            this.BrandOptions = result.Data;
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error",
            });
          }
        }
      );
    },
    //获取分类列表
    GetCategory() {
      axios.apiMethod(
        "/stores/Data/GetCategorys",
        "post",
        { ScreenValue: "" },
        (result) => {
          if (result.Code == 200) {
            this.CategoryOptions = result.Data;
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error",
            });
          }
        }
      );
    },

    OpenAttributeGroupItemWindow() {
      this.AttributeGroupItemWindow = true;
      this.AttributeGroupItemModel = {};
      this.AttributeGroupItemModel.GroupId = this.AttributeGroupModel.Id;
    },
    //点击属性组
    GetAttributesByGroupId(item) {
      this.AttributeGroupModel = item;
      (this.AttributeGroupItemModel = {
        Price: 0,
        Sort: 0,
      }), //当前操作属性
        axios.apiMethod(
          "/stores/GoodsManage/GetAttributesByGroupId",
          "get",
          { groupId: item.Id },
          (result) => {
            if (result.Code == 200) {
              this.AttributeGroupItemModel.GroupId =
                this.AttributeGroupModel.Id;

              this.AttributesGroupId = this.AttributeGroupModel.Id;
              this.AttributeGroupItemList = result.Data;
              this.AttributeItemWindow = true;
            } else {
              ElMessageBox({
                message: result.FullMsg,
                type: "error",
              });
            }
          }
        );
    },
    //插入属性
    InsertAttributeGroupItem() {
      axios.apiMethod(
        "/stores/GoodsManage/InsertAttributeList",
        "post",
        this.AttributeGroupItemModel,
        (result) => {
          if (result.Data.IsSuccess) {
            this.AttributeGroupItemModel = {
              Price: 0,
            };
            this.GetAttributesByGroupId(this.AttributeGroupModel);
          } else {
            ElMessage({
              message: result.Data.Msg,
              type: "success",
            });
          }
        }
      );
    },
    //创建属性项目
    CreateAttributeItem() {
      this.AttributeGroupItemWindow = true;
      this.AttributeGroupItemModel.GroupId = this.AttributeGroupModel.Id;
    },
    //删除属性项目
    DeleteAttributeItem(item) {
      ElMessageBox.confirm(
        "确定要删除<span style='color:red;font-size:14pt'>[" +
          item.Name +
          "]</span>吗" +
          "?",
        "提示",
        {
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          axios.apiMethod(
            "/stores/GoodsManage/DeletAttributeById",
            "get",
            { id: item.Id },
            (result) => {
              if (result.Code == 200) {
                this.GetAttributesByGroupId(this.AttributeGroupModel);
              } else {
                ElMessageBox({
                  message: result.FullMsg,
                  type: "error",
                });
              }
            }
          );
        })
        .catch(() => {
          // catch error
        });
    },

    //新增组
    InsterProductAttributeGroup() {
      axios.apiMethod(
        "/stores/GoodsManage/InsterProductAttributeGroup",
        "post",
        this.AttributeGroupModel,
        (result) => {
          if (result.Code == 200) {
            this.GetAttributeGroup();
            this.AttributeGroupWindow = false;
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error",
            });
          }
        }
      );
    },
    //删除组
    DeleteAttributeGroupById(item) {
      ElMessageBox.confirm(
        "确定要删除<span style='color:red;font-size:14pt'>[" +
          item.Name +
          "]</span>吗" +
          "?",
        "提示",
        {
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          axios.apiMethod(
            "/stores/GoodsManage/DeleteAttributeGroupById?id=" + item.Id,
            "get",
            {},
            (result) => {
              this.GetAttributeGroup();
            }
          );
        })
        .catch(() => {
          // catch error
        });
    },
    //打开属性编辑页面
    OpenAttributeGroupWindow(item) {
      axios.apiMethod(
        "/stores/GoodsManage/GetAttributeGroupDetail",
        "get",
        { id: item.Id },
        (result) => {
          this.AttributeGroupModel = result.Data;
          this.AttributeGroupWindow = true;
        }
      );

      // this.AttributeGroupModel = item;
      // this.AttributeGroupWindow = true;
    },
    //查询属性组
    GetAttributeGroup() {
      axios.apiMethod(
        "/stores/GoodsManage/GetAttributeGroup",
        "post",
        this.AttributePadding,
        (result) => {
          this.AttributeGroup = result.Data.Datas;
          if (this.AttributeGroup.length > 0)
            this.AttributeGroupModel = this.AttributeGroup[0];
        }
      );
    },
  },
};
</script>
  
<style scoped>
.editorContent {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.AboutusContent {
  max-width: 1600px;
  margin: 0 auto;
  padding: 30px;
}
</style>
  